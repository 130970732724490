<template lang="pug">
  #app
    Opening
    Menu
    .main(
      :class = "{ 'is-opening' : isMounted}"
      )
      .main__wrap
        Kv
        Description
        NeedlessFirstView
    Modal
    SpFixedBar
    PcFixedBar
</template>

<script>
import Menu from '@/components/Menu.vue';
import Kv from '@/components/Kv.vue';
import Description from '@/components/Description.vue';
import Modal from '@/components/Modal.vue';
import SpFixedBar from '@/components/SpFixedBar.vue';
import PcFixedBar from '@/components/PcFixedBar.vue';
import Opening from '@/components/Opening.vue';
import throttle from 'lodash/throttle';

export default {
  name: 'app',
  components: {
    Menu,
    Kv,
    Description,
    NeedlessFirstView: () => import('@/components/NeedlessFirstView.vue'),
    Modal,
    SpFixedBar,
    PcFixedBar,
    Opening,
  },
  data() {
    return {
      isMounted: false,
    };
  },
  created() {
    window.history.scrollRestoration = 'manual';
    this.setWindowSize();
  },
  mounted() {
    window.addEventListener('resize', this.setWindowSize);
    window.addEventListener('scroll', throttle(this.setPageYOffset, 1000));
    this.isMounted = true;
  },
  methods: {
    setWindowSize() {
      const { commit } = this.$store;
      commit('setWindowSize', {
        x: window.innerWidth,
        y: window.innerHeight,
      });
    },
    setPageYOffset() {
      const { commit } = this.$store;
      commit('setPageYOffset', window.pageYOffset);
    },
  },
};
</script>

<style lang="scss">
@use 'sass:math';
@use '@/assets/css/foundation/variables-easings';
@use '@/assets/css/foundation/mixin';

#app {
  overflow: hidden;

  @keyframes openingMainTranslate {
    0% {
      transform: translate3d(0, 100vh, 0);
    }
    100% {
      transform: translate3d(0, 0, 0);
    }
  }

  @keyframes openingMainFadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  .main {
    transform: translate3d(0, 100vh, 0);

    @include mixin.l-mobile {
      padding-bottom: math.div(114, 375) * 100%;
    }
    &__wrap {
      opacity: 0;
    }
    &.is-opening {
      animation-name: openingMainTranslate;
      animation-duration: 1s;
      animation-delay: 4s;
      animation-timing-function: variables-easings.$easeOutCubic;
      animation-fill-mode: forwards;
    }
    &.is-opening .main {
      &__wrap {
        animation-name: openingMainFadeIn;
        animation-duration: 1s;
        animation-delay: 4s;
        animation-timing-function: linear;
        animation-fill-mode: forwards;
      }
    }
  }
}
</style>
