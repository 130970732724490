import Vue from 'vue';
import Vuex from 'vuex';
import FixBody from '@/modules/FixBody';

Vue.use(Vuex);

const BREAK_POINTS = 768;

export default new Vuex.Store({
  state: {
    isMobile: false,
    resolution: {
      x: 0,
      y: 0,
    },
    pageYOffset: 0,
    menuOpened: false,
    modalOpened: false,
    FixBody: new FixBody(),
  },
  mutations: {
    setWindowSize(state, resolution) {
      state.resolution.x = resolution.x;
      state.resolution.y = resolution.y;
      state.isMobile = resolution.x < BREAK_POINTS;
    },
    setPageYOffset(state, pageYOffset) {
      state.pageYOffset = pageYOffset;
    },
    toggleMenu(state) {
      state.menuOpened = !state.menuOpened;
      if (state.menuOpened) {
        state.FixBody.set();
      } else {
        state.FixBody.cancel();
      }
    },
    toggleModal(state) {
      state.modalOpened = !state.modalOpened;
      if (state.modalOpened) {
        state.FixBody.set();
      } else {
        state.FixBody.cancel();
      }
    },
  },
});
