<template lang="pug">
  .menu
    button.menu__hamburger.menu__hamburger--close(
      :class = "{ 'is-opened' : $store.state.menuOpened }"
      @click = 'toggleMenu'
      aria-label = "ナビゲーションメニューを開く"
      type = "button"
      )
      .menu__hamburger-wrap.c-hover
        img(
        :src = "'data:image/svg+xml;charset=utf8,' + encodeURIComponent(menuIcon)"
        alt = ""
        )

    button.menu__hamburger.menu__hamburger--open(
      :class = "{ 'is-opened' : $store.state.menuOpened }"
      @click = 'toggleMenu'
      aria-label = "ナビゲーションメニューを閉じる"
      type = "button"
      )
      .menu__hamburger-wrap.c-hover
        svg(
          width="24"
          height="24"
          viewBox="0 0 24 24"
          )
          <path fill="#000" d="M19.425 6.697l-4.95 4.949 5.303 5.304-2.121 2.121-5.303-5.305-5.657 5.659-2.122-2.122 5.657-5.658L4.93 6.343l2.12-2.121 5.304 5.302 4.95-4.949 2.12 2.122z"/>

    .menu__bg(
        :class = "{ 'is-opened' : $store.state.menuOpened, 'is-clicked': isClicked }"
        @click = 'toggleMenu'
      )
    .menu__content(
        :class = "{ 'is-opened' : $store.state.menuOpened, 'is-clicked': isClicked }"
      )
      .menu__content-svgWrapper
        span(class = "u-visually-hidden") だけ、じゃない選び方 SELEC セレック

        svg(
          width="184"
          height="80"
          aria-hidden = "true"
          )
          use(xlink:href="#logo_selec")

      .menu__content-wrap
        .menu__content-items.menu__content-show-item.menu__content-show-item--1
          a.menu__content-item(
            href="#"
            v-scroll-to="'#app'"
            @click = 'toggleMenu'
            ) TOP
          a.menu__content-item(
            href="#"
            v-scroll-to="'#about'"
            @click = 'toggleMenu'
            ) SELECについて
        .menu__content-items-wrap
          .menu__content-items-in.menu__content-show-item.menu__content-show-item--2
            .menu__content-title
              |3つのライフスタイルデザイン
            .menu__content-items
              a.menu__content-item(
                href="#"
                v-scroll-to="'#kitchen'"
                @click = 'toggleMenu'
                ) キッチンチャット
              a.menu__content-item(
                href="#"
                v-scroll-to="'#madobe'"
                @click = 'toggleMenu'
                ) 窓辺チル
              a.menu__content-item(
                href="#"
                v-scroll-to="'#life'"
                @click = 'toggleMenu'
                ) ライフワーク
          .menu__content-items-in.menu__content-show-item.menu__content-show-item--3
            .menu__content-title
              |手軽なアレンジオプション
            .menu__content-items
              a.menu__content-item(
                href="#"
                v-scroll-to="'#option-layout'"
                @click = 'toggleMenu'
                ) 間取りのアレンジ
              a.menu__content-item(
                href="#"
                v-scroll-to="'#option-others'"
                @click = 'toggleMenu'
                ) そのほかのアレンジ
              a.menu__content-item(
                href="#"
                v-scroll-to="'#option-example'"
                @click = 'toggleMenu'
                ) オプション適用例
        .menu__content-items.menu__content-items--no-mb.menu__content-show-item.menu__content-show-item--4
          a.menu__content-item(
            href="#"
            v-scroll-to="'#app-link'"
            @click = 'toggleMenu'
            ) アプリのダウンロード
          a.menu__content-item(
            href="#"
            v-scroll-to="'#footer'"
            @click = 'toggleMenu'
            ) 会社情報
</template>

<script>
export default {
  name: 'Menu',
  data() {
    return {
      isClicked: false,
      // Menu までの描画で、img 要素がないと、LCP計測がエラーになってしまい困るので、苦肉の img element
      // そのうち LCP 対象にSVGも入ると信じている
      menuIcon: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#000" d="M22 17.5v3H2v-3h20zm0-7v3H2v-3h20zm0-7v3H2v-3h20"/></svg>`,
    };
  },
  methods: {
    toggleMenu() {
      if (!this.isClicked) {
        this.isClicked = true;
      }
      this.$store.commit('toggleMenu', false);
    },
  },
};
</script>

<style lang="scss" scoped>
@use 'sass:math';
@use '@/assets/css/foundation/variables';
@use '@/assets/css/foundation/variables-easings';
@use '@/assets/css/foundation/mixin';

.menu {
  @include mixin.l-pc {
    padding-top: math.div(104, 1440) * 100%;
  }

  @include mixin.l-tablet {
    padding-top: math.div(104, 768) * 100%;
  }

  @include mixin.l-mobile {
    padding-top: math.div(70, 375) * 100%;
  }
  &__hamburger {
    position: fixed;
    top: 0;
    z-index: 10000;

    @include mixin.l-more-than-mobile {
      width: 40px;
      height: 40px;
      cursor: pointer;
      top: 20px;
      right: 20px;
    }

    @include mixin.l-mobile {
      width: math.div(24, 375) * 100%;
      margin-top: math.div(24, 375) * 100%;
      right: math.div(16, 375) * 100%;
    }
    &-wrap {
      $-size: 100%;

      position: relative;
      height: 0;
      width: $-size;
      padding-bottom: $-size;
      svg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
    &-line {
      position: absolute;
      background-color: #000;

      @include mixin.l-more-than-mobile {
        width: math.div(27, 40) * 100%;
        padding-top: math.div(4, 40) * 100%;
        top: math.div(10, 40) * 100%;
        left: math.div(7, 40) * 100%;
      }

      @include mixin.l-mobile {
        width: math.div(16, 24) * 100%;
        padding-top: math.div(2, 24) * 100%;
        top: math.div(6, 24) * 100%;
        left: math.div(4, 24) * 100%;
      }
      &--2 {
        @include mixin.l-more-than-mobile {
          top: math.div(18, 40) * 100%;
        }

        @include mixin.l-mobile {
          top: math.div(11, 24) * 100%;
        }
      }
      &--3 {
        @include mixin.l-more-than-mobile {
          top: math.div(26, 40) * 100%;
        }

        @include mixin.l-mobile {
          top: math.div(16, 24) * 100%;
        }
      }
    }
  }
  &__bg {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;

    @include mixin.l-tablet {
      display: none;
    }

    @include mixin.l-mobile {
      display: none;
    }
  }
  &__content {
    overflow: scroll;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    background-color: #fff;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }

    @include mixin.l-pc {
      left: math.div(800, 1440) * 100%;
      padding: 0 math.div(50, 1440) * 100%;
    }

    @include mixin.l-tablet {
      left: 0;
      padding: 0 math.div(50, 768) * 100%;
    }

    @include mixin.l-mobile {
      left: 0;
      padding: 0 math.div(25, 375) * 100%;
    }
    &-svgWrapper {
      $-aspect-ratio: math.div(80, 184);

      position: relative;
      height: 0;
      z-index: 1001;
      margin-left: auto;
      margin-right: auto;

      @include mixin.l-more-than-mobile {
        $-width: math.div(184, 540) * 100%;

        width: $-width;
        padding-bottom: $-width * $-aspect-ratio;
      }

      @include mixin.l-mobile {
        $-width: math.div(138, 325) * 100%;

        width: $-width;
        padding-bottom: $-width * $-aspect-ratio;
      }
      svg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
    &-wrap {
      position: relative;
      z-index: 1001;

      @include mixin.l-more-than-mobile {
        margin-top: math.div(50, 540) * 100%;
      }

      @include mixin.l-mobile {
        margin-top: math.div(24, 325) * 100%;
      }
    }
    &-title {
      color: variables.$color-green-high;
      font-weight: bold;
      line-height: 1.1;

      @include mixin.l-more-than-mobile {
        margin-bottom: math.div(20, 540) * 100%;
        padding-left: 25px;

        @include mixin.fontSize(15);
      }

      @include mixin.l-mobile {
        margin-bottom: math.div(8, 325) * 100%;
        padding-left: math.div(20, 325) * 100%;

        @include mixin.fontSizeMobile(12);
      }
    }
    &-items {
      @include mixin.l-more-than-mobile {
        margin-bottom: math.div(40, 540) * 100%;
      }

      @include mixin.l-mobile {
        margin-bottom: math.div(25, 325) * 100%;
      }
      &--no-mb {
        margin-bottom: 0;
      }
      &-wrap {
        @include mixin.l-more-than-mobile {
          display: flex;
          margin-bottom: math.div(20, 540) * 100%;
        }
      }
      &-in {
        @include mixin.l-more-than-mobile {
          width: math.div(246, 540) * 100%;
          &:last-child {
            margin-left: math.div(48, 540) * 100%;
          }
        }
      }
    }
    &-item {
      display: block;
      position: relative;
      color: #000;
      text-decoration: none;

      @include mixin.l-more-than-mobile {
        padding-left: 25px;

        @include mixin.fontSize(18);

        line-height: math.div(48, 18);
      }

      @include mixin.l-mobile {
        padding-left: math.div(20, 325) * 100%;

        @include mixin.fontSizeMobile(16);

        line-height: math.div(32, 16);
      }
      &::before {
        width: 8px;
        height: 1px;
        content: '';
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 0;
        background-color: #000;
      }
    }
  }
  //
  //interaction
  //

  /* stylelint-disable no-duplicate-selectors, no-descending-specificity */
  &__hamburger {
    transition-property: opacity;
    transition-duration: 0.5s;
    transition-timing-function: ease;
    &--open {
      opacity: 0;
      pointer-events: none;
      &.is-opened {
        opacity: 1;
        pointer-events: auto;
      }
    }
    &--close {
      opacity: 1;
      pointer-events: auto;
      &.is-opened {
        opacity: 0;
        pointer-events: none;
      }
    }
    &--open & {
      &-line {
        &--1 {
          transform-origin: left;

          @include mixin.l-more-than-mobile {
            transform: rotate(45deg) translate3d(-2px, -2px, 0);
          }

          @include mixin.l-mobile {
            transform: rotate(45deg) translate3d(-1px, -1px, 0);
          }
        }
        &--3 {
          transform: rotate(-45deg);
          transform-origin: left;
        }
      }
    }
  }
  &__bg {
    pointer-events: none;
    transition-property: background;
    transition-timing-function: variables-easings.$easeOutCubic;
    &.is-clicked {
      transition-duration: 0.5s;
    }
    &.is-opened {
      pointer-events: auto;
      background-color: rgba(#000, 0.6);
    }
  }
  &__content {
    transform: translate3d(100%, 0, 0);
    pointer-events: none;
    transition-property: transform;
    transition-timing-function: variables-easings.$easeOutCubic;
    &.is-clicked {
      transition-duration: 0.5s;
    }
    &.is-opened {
      pointer-events: auto;
      transform: translate3d(0, 0, 0);
    }
    &-logo,
    &-show-item {
      opacity: 0;
      transition-property: opacity;
      transition-timing-function: linear;
    }
    &.is-clicked & {
      &-logo,
      &-show-item {
        transition-delay: 0.5s;
      }
    }
    &.is-opened & {
      &-logo,
      &-show-item {
        opacity: 1;
        transition-duration: 0.8s;
      }
      &-logo {
        transition-delay: 0.3s;
      }
      &-show-item {
        @for $-i from 1 through 4 {
          &--#{$-i} {
            transition-delay: (($-i - 1) * 0.1 + 0.4) * 1s;
          }
        }
      }
    }
  }
  /* stylelint-enable no-duplicate-selectors, no-descending-specificity */
}
</style>
