<template lang="pug">
.slide-in(
  :is = 'tag'
  v-scroll-item
  )
  .slide-in__dummy
    slot
  .slide-in__wrap
    .slide-in__wrap-in
      slot
</template>

<script>
export default {
  name: 'SlideInScrollItem',
  props: {
    tag: {
      type: String,
      default: 'div',
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/assets/css/foundation/variables-easings';

@keyframes slideInWrap {
  0% {
    transform: translate3d(-100%, 0, 0) translate3d(-10px, 0, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInWrapIn {
  0% {
    transform: translate3d(100%, 0, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
.slide-in {
  position: relative;
  &__dummy {
    opacity: 0;
  }
  &__wrap {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    transform: translate3d(-100%, 0, 0) translate3d(-10px, 0, 0);
    &-in {
      transform: translate3d(100%, 0, 0);
    }
  }
  &.is-shown & {
    &__wrap {
      animation-name: slideInWrap;
      animation-duration: 0.7s;
      animation-timing-function: variables-easings.$easeOutCubic;
      animation-fill-mode: both;
      &-in {
        animation-name: slideInWrapIn;
        animation-duration: 0.7s;
        animation-timing-function: variables-easings.$easeOutCubic;
        animation-fill-mode: both;
      }
    }
  }
}
</style>
