<template lang="pug">
  .modal(
    :class = "{ 'is-opened' : $store.state.modalOpened }"
    )
    .modal__wrap
      .modal__bg(
        @click = 'toggleModal'
        )
      .modal__in
        button.modal__close(
          @click = 'toggleModal'
          aria-label = "モーダルを閉じる"
          type = "button"
          )
          svg(
            width="24"
            height="24"
            viewBox="0 0 24 24"
            )
            <path fill="#FFF" d="M18.364 7.05L13.414 12l4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636l4.95 4.95 4.95-4.95 1.414 1.414z"/>

        .modal__content
          .modal__desc
            |SELECは2社の強みを生かした
            br
            |リノベーションマンションの
            br.u-dn-more-mobile
            |あたらしい提案です
          .modal__logo
            .modal__logo-starmica
              span(class = "u-visually-hidden") スター・マイカ

              svg(
                width="167"
                height="25"
                aria-hidden = "true"
                )
                use(xlink:href="#logo_starmica")
              
            .modal__logo-cross
              svg(
                width="20"
                height="21"
                )
                use(xlink:href="#cross_s")

            .modal__logo-cowcamo
              span(class = "u-visually-hidden") カウカモ

              svg(
                width = "297.7"
                height = "50.7"
                aria-hidden = "true"
                )
                use(xlink:href="#logo_cowcamo")

          .modal__illust
            .modal__illust-item
              .modal__illust-item-head
                .modal__illust-item-starmica
                  svg(
                    width="79"
                    height="80"
                    viewBox="0 0 79 80"
                    )
                    g(fill="none" fill-rule="evenodd")
                      path(fill="#DDDFE2" fill-rule="nonzero" d="M61.849,75.276 L61.849,79.372 L19.689,79.372 L19.689,75.276 L61.849,75.276 Z M58.969,68.056 L58.969,75.276 L22.569,75.276 L22.569,68.056 L58.969,68.056 Z M44.844,57.302 C45.4776296,62.5050741 48.7255456,65.1537078 53.3963127,66.8751631 L54.0002184,67.090012 L54.0002184,67.090012 L54.6190321,67.2955398 C54.7233794,67.3290594 54.828325,67.3622221 54.9338572,67.3950433 L55.574,67.588 L55.574,67.588 L57.092,68.031 L24.446,68.03 L25.1254429,67.825984 C25.3496362,67.7567061 25.5715138,67.6861104 25.7909919,67.6140617 L26.442144,67.3934211 C31.5924321,65.5912593 35.3123333,62.8742222 36.443,57.372 L44.844,57.302 Z")
                      path(fill="#EAE5B5" fill-rule="nonzero" d="M67.207,37.917 C66.101,42.241 64.277,45.614 60.011,46.732 L55.612,47.638 L57.112,45.018 L60.265,30.097 L60.299,27.275 L69.929,27.275 L67.207,37.917 Z M26.57,48.347 C27.493,49.692 28.533,50.957 29.674,52.132 C28.531,50.959 27.494,49.692 26.57,48.346 L26.57,48.347 Z M70.979,22.039 L60.362,22.039 L60.395,19.327 L60.265,19.692 L60.265,15.97 L21.453,15.97 L21.453,31.827 C21.453,37.705 23.213,43.325 26.349,48.031 C26.261,47.898 26.162,47.773 26.075,47.639 L21.677,46.733 C17.411,45.615 15.587,42.243 14.481,37.918 L11.758,27.275 L21.423,27.275 L21.423,22.04 L5.115,22.04 L6.463,27.414 C6.474,27.464 6.48,27.512 6.493,27.561 L6.91,29.196 L9.478,39.23 C11.063,45.426 14.342,50.25 20.464,51.823 C20.504,51.833 20.544,51.843 20.584,51.85 L31.846,54.17 C31.753,54.092 31.669,54.006 31.576,53.928 C32.971,55.123 34.486,56.196 36.116,57.117 C36.139,57.13 36.164,57.139 36.188,57.152 C36.186,57.152 36.184,57.154 36.183,57.155 C36.277,57.207 36.378,57.243 36.473,57.292 C36.725,57.421 36.977,57.548 37.236,57.653 C37.379,57.71 37.523,57.753 37.667,57.803 C37.851,57.869 38.033,57.943 38.22,57.997 L38.235,57.988 C40.681,58.682 43.33,58.399 45.603,57.117 C46.21,56.773 46.797,56.402 47.372,56.016 L47.368,56.027 L50.676,53.997 L61.103,51.85 C61.144,51.843 61.183,51.833 61.224,51.823 C67.346,50.25 70.625,45.426 72.21,39.23 L74.778,29.195 L75.196,27.56 C75.208,27.511 75.214,27.462 75.224,27.413 L76.574,22.039 L70.979,22.039 L70.979,22.039 Z")
                      path(stroke="#000" d="M34.025,54.922 C32.752,61.112 28.202,63.776 22.027,65.58 M54.673,65.58 L53.157,65.138 C47.333,63.436 43.153,60.83 42.426,54.852 M50.097,65.606 L48.244,65.606 L47.283,65.606 L20.15,65.606 L20.15,72.825 L49.136,72.825 L50.097,72.825 L56.55,72.825 L56.55,65.606 L50.097,65.606 Z M17.271,76.922 L59.43,76.922 L59.43,72.825 L17.271,72.825 L17.271,76.922 Z M57.668,19.409 L57.668,13.339 L18.856,13.339 L18.856,29.196 C18.856,39.696 24.466,49.373 33.518,54.486 C33.543,54.5 33.568,54.509 33.591,54.522 L33.586,54.524 C33.681,54.577 33.782,54.613 33.878,54.662 C34.129,54.791 34.381,54.917 34.64,55.022 C34.782,55.08 34.927,55.122 35.07,55.173 C35.256,55.239 35.437,55.313 35.625,55.367 L35.638,55.357 C38.084,56.051 40.734,55.769 43.006,54.487 C44.523,53.63 45.933,52.635 47.246,51.539 M68.383,19.409 L57.668,19.409 L57.668,24.645 L67.334,24.645 L64.61,35.286 C63.504,39.611 61.68,42.984 57.414,44.102 L53.016,45.008 C51.442,47.453 49.501,49.658 47.246,51.539 L58.507,49.22 C58.547,49.212 58.587,49.203 58.627,49.192 C64.75,47.62 68.03,42.795 69.615,36.6 L72.183,26.564 L72.183,26.564 L72.6,24.93 C72.613,24.881 72.619,24.832 72.63,24.783 L73.978,19.409 L68.384,19.409 L68.383,19.409 Z")
                      path(stroke="#000" d="M8.113,19.409 L18.827,19.409 L18.827,24.645 L9.162,24.645 L11.885,35.286 C12.991,39.611 14.815,42.984 19.081,44.102 L23.479,45.008 C25.053,47.453 26.994,49.658 29.249,51.539 L17.989,49.22 C17.948,49.212 17.908,49.203 17.867,49.192 C11.747,47.62 8.467,42.795 6.882,36.6 L4.314,26.564 L4.314,26.564 L3.896,24.93 C3.883,24.881 3.878,24.832 3.867,24.783 L2.518,19.41 L8.113,19.41 L8.113,19.409 Z M53.016,45.008 C55.994,40.382 57.668,34.914 57.668,29.195 L57.668,24.645")
                      polygon(fill="#FFF" fill-rule="nonzero" points="42.719 27.534 42.719 22.126 28.48 22.126 28.48 43.597 31.109 43.597 40.09 43.597 42.719 43.597 51.443 43.597 51.443 27.534")
                      path(stroke="#000" d="M26.886,41.912 L41.146,41.912 L41.146,20.512 L26.886,20.512 L26.886,41.912 Z M41.126,41.9090014 L49.85,41.9090014 L49.85,25.9164745 L41.126,25.9164745 L41.126,41.9090014 Z")
                      polygon(fill="#DDDFE2" fill-rule="nonzero" points="30.312 43.516 39.294 43.516 39.294 38.189 30.312 38.189")
                      path(stroke="#000" d="M38.497,36.575 L38.497,41.902 L29.516,41.902 L29.516,36.575 L38.497,36.575 Z M47.088,35.603 L47.088,38.845 L43.888,38.845 L43.888,35.603 L47.088,35.603 Z M38.497,30.288 L38.497,33.252 L29.516,33.252 L29.516,30.288 L38.497,30.288 Z M47.088,29.058 L47.088,32.3 L43.888,32.3 L43.888,29.058 L47.088,29.058 Z M38.497,23.834 L38.497,26.797 L29.516,26.797 L29.516,23.834 L38.497,23.834 Z")
              .modal__illust-item-body
                |中古マンション
                br
                |保有数業界トップ
                span ※
            .modal__illust-item
              .modal__illust-item-head
                .modal__illust-item-cowcamo
                  svg(
                    width="86"
                    height="80"
                    viewBox="0 0 86 80"
                    fill="none"
                    )
                    <path fill="#DDDFE2" d="M31.119 44.693l53.486-2.752-42.312-30.87zM20.17 77.637l-4.426-1.299L38.973 6.441 43.4 7.74z"/><path stroke="#000" d="M30.25 41.696l9.226-27.758m34.908 17.978l9.353 7.029-37.639 1.936m-6.696-26.964L19.228 74.619l-4.427-1.299 7.528-22.653m14.464-43.52l1.238-3.724 4.426 1.3-1.106 3.33M27.127 36.232l7.832-23.567"/><path fill="#EAE5B5" d="M30.408 9.304l50.181 17.611-50.181 17.612z"/><path fill="#DDDFE2" d="M25.77 79.041h4.638V5.814H25.77z"/><path stroke="#000" d="M28.629 6.686l50.179 17.612-50.179 17.611zm-.078 45.732v24.006h-4.638V3.198h4.638v45.047"/><path fill="#FFF" d="M39.041 55.416H36.4c1.467 0 2.74-1.196 2.641-2.575-.097-1.38-1.272-2.39-2.74-2.39H25.735v-4.966h-3.522c-1.174 0-2.251.368-3.23 1.011l-5.773 4.138c-.88.644-2.054 1.012-3.229 1.012H6.556v18.85H33.66c1.468 0 2.642-1.011 2.74-2.39.097-1.38-1.175-2.575-2.642-2.575H36.4c1.467 0 2.641-1.012 2.74-2.391.097-1.38-1.175-2.574-2.643-2.574h2.642c1.468 0 2.74-1.196 2.642-2.575 0-1.563-1.272-2.575-2.74-2.575"/><path fill="#FFF" d="M2.447 52.749v16.46c0 .644.587 1.195 1.272 1.195h2.935v-18.85H3.72c-.685 0-1.272.552-1.272 1.196"/><path stroke="#000" d="M37.084 53.577h-2.641c1.467 0 2.74-1.196 2.641-2.575-.097-1.38-1.272-2.39-2.74-2.39H23.778v-4.966h-3.522c-1.174 0-2.251.368-3.23 1.011l-5.773 4.138c-.88.644-2.054 1.012-3.229 1.012H4.6v18.85h27.104c1.468 0 2.642-1.011 2.74-2.39.097-1.38-1.175-2.575-2.642-2.575h2.642c1.467 0 2.641-1.012 2.74-2.391.097-1.38-1.175-2.574-2.643-2.574h2.642c1.468 0 2.74-1.196 2.642-2.576 0-1.562-1.272-2.574-2.74-2.574z"/><path stroke="#000" d="M4.697 49.715v18.85H1.762c-.685 0-1.272-.551-1.272-1.195V50.91c0-.643.587-1.195 1.272-1.195h2.935zm29.844 3.862h-7.828m8.165 5.174h-8.185m6.022 4.969h-5.97"/><path stroke="#000" stroke-dasharray="2" d="M73.842 4.573l-3.425 5.149"/><path stroke="#000" d="M77.952 11.01l-3.425 1.655M65.72 4.389l.49 3.678"/>

              .modal__illust-item-body
                |リノベ物件の
                br
                |豊富なプロデュース実績
          .modal__text
            p
              |これまでにない不動産流通のカタチを創造し、良質な中古住宅を手に届きやすい価格で提供することで、より多くの人の豊かな暮らしを実現してきた「スター・マイカ」。
            p
              |数ある中古・リノベーション住宅の中から、理想の"一点もの"との出会いを提供することを目指し、物件のプロデュースも数多く手がけてきた「cowcamo（カウカモ）」。
            p
              |スター・マイカが提供する物件と、カウカモによるプロデュース。2社の強みを生かして、リノベーションマンションのあたらしい買い方「SELEC」ができました。
          .modal__note
            |中古マンション事業を営む上場企業各社の最新決算情報（2020年11月期末時点）に基づくスター・マイカ調べによります。
</template>

<script>
export default {
  name: 'Modal',
  methods: {
    toggleModal() {
      this.$store.commit('toggleModal', false);
    },
  },
};
</script>

<style lang="scss" scoped>
@use 'sass:math';
@use '@/assets/css/foundation/variables';
@use '@/assets/css/foundation/variables-easings';
@use '@/assets/css/foundation/mixin';

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;
  overflow: scroll;
  background-color: rgba(#000, 0.6);
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  img {
    width: 100%;
    display: block;
  }
  &__wrap {
    position: relative;
    z-index: 1001;

    @include mixin.l-more-than-mobile {
      padding: math.div(83, 1440) * 100% 0;
    }

    @include mixin.l-mobile {
      padding: math.div(56, 375) * 100% math.div(20, 375) * 100%;
    }
  }
  &__in {
    position: relative;
    z-index: 1001;
    background-color: #fff;
    border-radius: 4px;
    box-sizing: border-box;

    @include mixin.l-more-than-mobile {
      width: math.div(640, 1440) * 100%;
      min-width: 640px;
      margin-left: auto;
      margin-right: auto;
      padding: math.div(60, 1440) * 100% math.div(56, 1440) * 100%;
    }

    @include mixin.l-mobile {
      padding: math.div(20, 335) * 100%;
      padding-top: math.div(54, 335) * 100%;
    }
  }
  &__bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1001;
  }
  &__close {
    height: 0;
    cursor: pointer;
    position: absolute;
    top: 0;

    @include mixin.l-more-than-mobile {
      $-size: math.div(40, 640) * 100%;

      width: $-size;
      padding-bottom: $-size;
      right: -1 * math.div(1, 640) * 100%;
      margin-top: -1 * math.div(48, 640) * 100%;
    }

    @include mixin.l-mobile {
      $-size: math.div(20, 295) * 100%;

      width: $-size;
      padding-bottom: $-size;
      right: -1 * math.div(1, 295) * 100%;
      margin-top: -1 * math.div(28, 295) * 100%;
    }
    svg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  &__desc {
    font-weight: bold;
    text-align: center;

    @include mixin.l-more-than-mobile {
      @include mixin.fontSize(20);

      line-height: math.div(40, 20);
      letter-spacing: math.div(0.67, 20) * 1em;
    }

    @include mixin.l-mobile {
      @include mixin.fontSizeMobile(18);

      line-height: 1.5;
    }
  }
  &__logo {
    display: flex;
    justify-content: center;
    align-items: center;

    @include mixin.l-more-than-mobile {
      margin-top: math.div(41, 528) * 100%;
    }

    @include mixin.l-mobile {
      margin-top: math.div(30, 295) * 100%;
    }
    &-starmica {
      $-aspect-ratio: math.div(25, 167);

      position: relative;
      height: 0;
      flex-shrink: 0;

      @include mixin.l-more-than-mobile {
        $-width: math.div(151, 528) * 100%;

        width: $-width;
        padding-bottom: $-width * $-aspect-ratio;
      }

      @include mixin.l-mobile {
        $-width: math.div(128, 295) * 100%;

        width: $-width;
        padding-bottom: $-width * $-aspect-ratio;
      }
      svg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
    &-cross {
      $-aspect-ratio: math.div(21, 20);

      position: relative;
      height: 0;
      flex-shrink: 0;

      @include mixin.l-more-than-mobile {
        $-width: math.div(24, 528) * 100%;

        width: $-width;
        padding-bottom: $-width * $-aspect-ratio;
        margin-left: math.div(20, 528) * 100%;
        margin-right: math.div(21, 528) * 100%;
      }

      @include mixin.l-mobile {
        $-width: math.div(24, 295) * 100%;

        width: $-width;
        padding-bottom: $-width * $-aspect-ratio;
        margin-left: math.div(10, 295) * 100%;
        margin-right: math.div(12, 295) * 100%;
      }
      svg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
    &-cowcamo {
      $-aspect-ratio: math.div(50.7, 297.7);

      position: relative;
      height: 0;
      flex-shrink: 0;

      @include mixin.l-more-than-mobile {
        $-width: math.div(147, 528) * 100%;

        width: $-width;
        padding-bottom: $-width * $-aspect-ratio;
      }

      @include mixin.l-mobile {
        $-width: math.div(128, 295) * 100%;

        width: $-width;
        padding-bottom: $-width * $-aspect-ratio;
      }
      svg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
  &__illust {
    display: flex;
    justify-content: center;

    @include mixin.l-more-than-mobile {
      margin-top: math.div(17, 528) * 100%;
    }

    @include mixin.l-mobile {
      margin-top: math.div(36, 295) * 100%;
    }
    &-item {
      @include mixin.l-more-than-mobile {
        width: math.div(150, 528) * 100%;
      }

      @include mixin.l-mobile {
        width: math.div(120, 295) * 100%;
      }
      &:last-child {
        @include mixin.l-more-than-mobile {
          margin-left: math.div(80, 528) * 100%;
        }

        @include mixin.l-mobile {
          margin-left: math.div(40, 295) * 100%;
        }
      }
      &-head {
        position: relative;
        padding-top: 100%;
        background-color: variables.$color-gray-light;
        border-radius: 50%;
      }
      &-starmica {
        $-aspect-ratio: math.div(80, 79);

        height: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);

        @include mixin.l-more-than-mobile {
          $-width: math.div(79, 150) * 100%;

          width: $-width;
          padding-bottom: $-width * $-aspect-ratio;
        }

        @include mixin.l-mobile {
          $-width: math.div(72, 120) * 100%;

          width: $-width;
          padding-bottom: $-width * $-aspect-ratio;
        }
        svg {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
      &-cowcamo {
        $-aspect-ratio: math.div(80, 86);

        height: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);

        @include mixin.l-more-than-mobile {
          $-width: math.div(79, 150) * 100%;

          width: $-width;
          padding-bottom: $-width * $-aspect-ratio;
        }

        @include mixin.l-mobile {
          $-width: math.div(72, 120) * 100%;

          width: $-width;
          padding-bottom: $-width * $-aspect-ratio;
        }
        svg {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
      &-body {
        font-weight: bold;
        text-align: center;
        white-space: nowrap;

        @include mixin.l-more-than-mobile {
          margin-top: math.div(16, 150) * 100%;

          @include mixin.fontSize(13);

          line-height: math.div(18, 12);
        }

        @include mixin.l-mobile {
          margin-top: math.div(22, 120) * 100%;

          @include mixin.fontSizeMobile(12);

          line-height: math.div(20, 12);
        }
        span {
          @include mixin.l-more-than-mobile {
            @include mixin.fontSize(11);
          }

          @include mixin.l-mobile {
            @include mixin.fontSizeMobile(10);
          }
        }
      }
    }
  }
  &__text {
    @include mixin.l-more-than-mobile {
      margin-top: math.div(40, 528) * 100%;

      @include mixin.fontSize(16);

      line-height: math.div(27, 16);
    }

    @include mixin.l-mobile {
      margin-top: math.div(29, 295) * 100%;

      @include mixin.fontSizeMobile(15);

      line-height: math.div(26, 15);
    }
    p {
      @include mixin.l-more-than-mobile {
        margin-top: math.div(27, 528) * 100%;
      }

      @include mixin.l-mobile {
        margin-top: math.div(26, 295) * 100%;
      }
      &:first-child {
        margin-top: 0;
      }
    }
  }
  &__note {
    position: relative;
    padding-left: 1.3em;
    padding-right: 0.5em;

    @include mixin.l-more-than-mobile {
      margin-top: math.div(32, 528) * 100%;

      @include mixin.fontSize(14);

      line-height: math.div(21, 14);
    }

    @include mixin.l-mobile {
      margin-top: math.div(26, 295) * 100%;

      @include mixin.fontSizeMobile(12);

      line-height: math.div(21, 12);
    }
    &::before {
      content: '※';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  //
  //interaction
  //
  opacity: 0;
  pointer-events: none;
  transition-property: opacity;
  transition-duration: 0.8s;
  transition-timing-function: variables-easings.$easeOutCubic;
  &.is-opened {
    opacity: 1;
    pointer-events: auto;
  }
}
</style>
