<template lang="pug">
.fade-in-up(
  v-scroll-item
  :is = 'tag'
  :style = "{ animationDelay: `${delay}s` }"
  )
  .fade-in-up__wrap(
    :style = 'styles'
    )
    slot
</template>

<script>
export default {
  name: 'FadeInUpScrollItem',
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    delay: {
      type: String,
      default: '0',
    },
    height: {
      type: String,
      default: 'auto',
    },
  },
  computed: {
    styles() {
      return {
        animationDelay: `${this.delay}s`,
        height: this.height,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/assets/css/foundation/variables-easings';

@keyframes fadeInUpTranslate {
  0% {
    transform: translate3d(0, 30px, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInUpOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.fade-in-up {
  transform: translate3d(0, 30px, 0);
  backface-visibility: hidden;
  &__wrap {
    opacity: 0;
    backface-visibility: hidden;
  }
  &.is-shown {
    animation-name: fadeInUpTranslate;
    animation-duration: 0.7s;
    animation-timing-function: variables-easings.$easeOutCubic;
    animation-fill-mode: both;
  }
  &.is-shown & {
    &__wrap {
      animation-name: fadeInUpOpacity;
      animation-duration: 0.7s;
      animation-timing-function: linear;
      animation-fill-mode: both;
    }
  }
}
</style>
