<template lang="pug">
  .sp-fixed-bar(
    :class = "{ 'is-shown' : scrollY}"
    )
    .sp-fixed-bar__wrap
      .sp-fixed-bar__btn
        Bar(
          eventLabel = 'button_float'
          )
      .sp-fixed-bar__text
        |アプリのダウンロードがお済みでない場合はアプリストアに移動します
</template>

<script>
import Bar from '@/components/_common/Bar.vue';

export default {
  name: 'SpFixedBar',
  components: {
    Bar,
  },
  data() {
    return {
      beforeY: 0,
    };
  },
  computed: {
    scrollY() {
      let isShown;

      if (
        this.$store.state.pageYOffset > 0 ||
        (this.beforeY > 0 && this.$store.state.menuOpened) ||
        (this.beforeY > 0 && this.$store.state.modalOpened)
      ) {
        isShown = true;
      } else {
        isShown = false;
      }

      this.updateBeforeY(this.$store.state.pageYOffset);
      return isShown;
    },
  },
  methods: {
    updateBeforeY(y) {
      this.beforeY = y;
    },
  },
};
</script>

<style lang="scss" scoped>
@use 'sass:math';
@use '@/assets/css/foundation/variables-easings';
@use '@/assets/css/foundation/mixin';

.sp-fixed-bar {
  @include mixin.l-more-than-mobile {
    display: none;
  }

  @include mixin.l-mobile {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    padding-bottom: env(safe-area-inset-bottom);
    background-color: rgba(#fff, 0.8);
  }
  &__wrap {
    padding: math.div(15, 375) * 100% math.div(20, 375) * 100%;
  }
  &__text {
    @include mixin.l-mobile {
      margin-top: 10px;

      @include mixin.fontSizeMobile(10);
    }
  }
  //
  //interaction
  //
  transform: translate3d(0, 100%, 0);
  transition-duration: 1s;
  transition-timing-function: variables-easings.$easeOutBack;
  &.is-shown {
    transform: translate3d(0, 0, 0);
  }
}
</style>
