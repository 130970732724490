<template lang="pug">
  .pc-fixed-bar(
    :class = "{ 'is-shown' : isMounted}"
    )
    a.pc-fixed-bar__btn.c-hover(
      :href = 'mixLink'
      @click = 'tagToApp'
      )
      .pc-fixed-bar__btn-label
        |販売中の物件を見る
</template>

<script>
export default {
  name: 'PcFixedBar',
  data() {
    return {
      isMounted: false,
      mixLink:
        'https://vcq8z.app.goo.gl/?link=https://cowcamo.jp/dispatch?path%3D/mixes/90&apn=com.tsukuruba.cowcamo.z&isi=1282237769&ibi=com.tsukuruba.cowcamo.z&ofl=https://cowcamo.jp/selec',
    };
  },
  mounted() {
    this.isMounted = true;
  },
  methods: {
    tagToApp() {
      this.$gtag.event('click', {
        event_category: 'selec_list',
        event_label: 'button_float',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/assets/css/foundation/variables';
@use '@/assets/css/foundation/variables-easings';
@use '@/assets/css/foundation/mixin';

.pc-fixed-bar {
  width: 216px;
  height: 80px;
  display: block;
  position: fixed;
  top: 0;
  right: 80px;
  z-index: 100;

  @include mixin.l-mobile {
    display: none;
  }
  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: variables.$color-primary;
    color: #fff;

    @include mixin.fontSize(16);

    font-weight: bold;
    text-decoration: none;
  }
  //
  //interaction
  //
  transform: translate3d(0, -100%, 0);
  transition-duration: 1s;
  transition-timing-function: variables-easings.$easeOutBack;
  &.is-shown {
    transform: translate3d(0, 0, 0);
    transition-delay: 5s;
  }
}
</style>
