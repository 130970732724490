<template lang="pug">
.fade-in(
  v-scroll-item
  :is = 'tag'
  :style = "{ animationDelay: `${delay}s` }"
  )
  slot
</template>

<script>
export default {
  name: 'FadeInScrollItem',
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    delay: {
      type: String,
      default: '0',
    },
  },
};
</script>

<style lang="scss" scoped>
.fade-in {
  opacity: 0;
  backface-visibility: hidden;
  &.is-shown {
    animation-name: fadeIn;
    animation-duration: 0.7s;
    animation-timing-function: ease;
    animation-fill-mode: both;
  }
}
</style>
