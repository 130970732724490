import Vue from 'vue';
import VueGtag from 'vue-gtag';
import VueScrollTo from 'vue-scrollto';
import 'intersection-observer'; // Polyfill for IE
import initIntersectionObserver from './modules/InitIntersectionObserver';
import App from './App.vue';
import store from './store';
import './assets/css/main.scss';

Vue.directive('scroll-item', {
  inserted(el) {
    const option = {
      root: null,
      rootMargin: '-20% 0% -20% 0%',
      threshold: 0,
    };

    initIntersectionObserver(el, option, () => el.classList.add('is-shown'));
  },
});

Vue.use(VueGtag, {
  config: {
    id: 'UA-57508730-6',
    params: {
      send_page_view: true,
    },
  },
});
Vue.use(VueScrollTo);

Vue.config.productionTip = false;

new Vue({
  store,
  render: (h) => h(App),
}).$mount('#app');
