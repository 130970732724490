<template lang="pug">
  .kv
    h1.kv__logo
      .kv__logo-svgWrapper
        span(class = "u-visually-hidden") だけ、じゃない選び方 SELEC セレック

        svg(
          width="184"
          height="80"
          aria-hidden = "true"
          )
          use(xlink:href="#logo_selec")

    .kv__image
      template(v-if="isObjectFitSupported")
        template(v-if="isMobile")
          .kv__image-wrapper(
            v-for = 'n in 3'
            :key = 'n'
            @animationend = 'animationEnd($event)'
          )
            picture
              source(
                type = "image/webp"
                :srcset = "require('@/assets/images/kv/kv'+ n + '_sp.webp')"
              )

              img(
                :src = "require('@/assets/images/kv/kv'+ n + '_sp.jpg')"
                alt = ""
                width = "1530"
                height = "1020"
              )

        template(v-else)
          .kv__image-wrapper(
            v-for = 'n in 3'
            :key = 'n'
            @animationend = 'animationEnd($event)'
            )
            picture
              source(
                type = "image/webp"
                :srcset = "require('@/assets/images/kv/kv'+ n + '_pc.webp')"
              )
              
              img(
                :src = "require('@/assets/images/kv/kv'+ n + '_pc.jpg')"
                alt = ""
                width = "2040"
                height = "1360"
              )

      template(v-else)
        .kv__image-wrapper.kv__image-wrapper--for-ie(
          v-for = 'n in 3'
          :key = 'n'
          :style = "{ backgroundImage: `url(${require('@/assets/images/kv/kv' + n + '_pc.jpg')})` }"
          @animationend = 'animationEnd($event)'
          )

    .kv__copy(
      :class = "{ 'is-opening': isMounted }"
    )
      .kv__copy-item
        img.kv__copy-item-dummy(
          src = "@/assets/images/kv/cpy1.png"
          alt = ""
          width = "48"
          height = "432"
        )
        .kv__copy-item-wrap
          img(
          src = "@/assets/images/kv/cpy1.png"
          alt = "出来上がったものを"
          width = "48"
          height = "432"
        )

      .kv__copy-item
        img.kv__copy-item-dummy(
        src = "@/assets/images/kv/cpy2.png"
        alt = ""
        width = "48"
        height = "402"
      )
        .kv__copy-item-wrap
          img(
            src = "@/assets/images/kv/cpy2.png"
            alt = "買うだけじゃない。"
            width = "48"
            height = "402"
          )

      .kv__copy-item
        img.kv__copy-item-dummy(
        src = "@/assets/images/kv/cpy3.png"
        alt = ""
        width = "48"
        height = "382"
      )
        .kv__copy-item-wrap
          img(
          src = "@/assets/images/kv/cpy3.png"
          alt = "もっと自分らしい"
          width = "48"
          height = "382"
        )

      .kv__copy-item
        img.kv__copy-item-dummy(
        src = "@/assets/images/kv/cpy4.png"
        alt = ""
        width = "48"
        height = "358"
      )
        .kv__copy-item-wrap
          img(
          src = "@/assets/images/kv/cpy4.png"
          alt = "住まいの選び方。"
          width = "48"
          height = "358"
        )

    .kv__scroll
      .kv__scroll-text scroll
      .kv__scroll-bar
        .kv__scroll-bar-in
</template>

<script>
export default {
  name: 'Kv',
  data() {
    return {
      isMounted: false,
      index: 0,
      imgItems: null,
    };
  },
  mounted() {
    this.isMounted = true;
    this.imgItems = this.$el.querySelectorAll('.kv__image-wrapper');
    setTimeout(() => {
      this.imgItems[this.index].classList.add('is-animate');
      setInterval(() => {
        this.switchImage();
      }, 4000);
    }, 3800);
  },
  methods: {
    switchImage() {
      this.index += 1;
      if (this.index > 2) {
        this.index = 0;
      }
      this.imgItems[this.index].classList.add('is-animate');
    },
    animationEnd(e) {
      e.target.classList.remove('is-animate');
    },
  },
  computed: {
    isObjectFitSupported() {
      return 'objectFit' in document.documentElement.style === true;
    },
    isMobile() {
      return this.$store.state.isMobile;
    },
  },
};
</script>

<style lang="scss" scoped>
@use 'sass:math';
@use '@/assets/css/foundation/variables';
@use '@/assets/css/foundation/variables-easings';
@use '@/assets/css/foundation/mixin';

.kv {
  position: relative;
  z-index: 1;

  @include mixin.l-pc {
    margin-left: 80px;
    margin-right: 80px;
  }

  @include mixin.l-tablet {
    margin-left: 40px;
    margin-right: 40px;
  }
  &__logo {
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 10;
    margin-bottom: 0;

    @include mixin.l-more-than-mobile {
      margin-top: -1 * math.div(65, 1280) * 100%;
    }

    @include mixin.l-tablet {
      margin-top: -1 * math.div(63, 668) * 100%;
    }

    @include mixin.l-mobile {
      margin-top: -1 * math.div(47, 375) * 100%;
    }
    &-svgWrapper {
      $-aspect-ratio: math.div(80, 184);

      position: relative;
      height: 0;
      margin-left: auto;
      margin-right: auto;

      @include mixin.l-more-than-mobile {
        $-width: math.div(240, 1280) * 100%;

        width: $-width;
        padding-bottom: $-width * $-aspect-ratio;
      }

      @include mixin.l-tablet {
        $-width: math.div(240, 668) * 100%;

        width: $-width;
        padding-bottom: $-width * $-aspect-ratio;
      }

      @include mixin.l-mobile {
        $-width: math.div(184, 375) * 100%;

        width: $-width;
        padding-bottom: $-width * $-aspect-ratio;
      }
      svg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
  &__image {
    overflow: hidden;
    position: relative;

    @include mixin.l-pc {
      /* 0.07222... = 104 / 1440 */
      height: calc(100vh - (0.0722 * 100vw) - 80px);
    }

    @include mixin.l-tablet {
      padding-top: math.div(880, 668) * 100%;
    }

    @include mixin.l-mobile {
      /* 0.36 = 135/375 */
      height: calc(100vh - 0.36 * 100vw);
    }
    &-wrapper {
      position: absolute;
      top: 0;
      left: -30px;
      right: -30px;
      bottom: 0;
      img {
        object-fit: cover;
        height: 100%;
      }
      &--for-ie {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
  }
  &__copy {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start;
    position: absolute;
    z-index: 10;

    @include mixin.l-more-than-mobile {
      bottom: 80px;
      right: 40px;
    }

    @include mixin.l-mobile {
      bottom: 18px;
      right: 18px;
    }
    &-item {
      position: relative;

      @include mixin.l-more-than-mobile {
        width: 32px;
        margin-right: 8px;
      }

      @include mixin.l-mobile {
        width: 24px;
        margin-right: 4px;
      }
      &:first-child {
        margin-right: 0;
      }
      img {
        width: 100%;
        display: block;
      }
      &-dummy {
        opacity: 0;
      }
      &-wrap {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: hidden;
      }
    }
  }
  &__scroll {
    position: absolute;
    bottom: 0;
    left: 50%;
    z-index: 10;
    transform: translate3d(-50%, 0, 0);
    &-text {
      margin-bottom: 9px;
      color: #fff;
      font-family: variables.$font-helvetica;
      line-height: 1;
      text-transform: uppercase;
      text-align: center;

      @include mixin.l-more-than-mobile {
        @include mixin.fontSize(11);
      }

      @include mixin.l-mobile {
        @include mixin.fontSizeMobile(11);
      }
    }
    &-bar {
      width: 1px;
      height: 16px;
      position: relative;
      margin-left: auto;
      margin-right: auto;
      &-in {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: #fff;
      }
    }
  }
  //
  //interaction
  //

  /* stylelint-disable no-duplicate-selectors, no-descending-specificity */
  @keyframes kv {
    0% {
      opacity: 0;
      transform: translate3d(0, 0, 0);
    }
    10% {
      opacity: 1;
      transform: translate3d(-4px, 0, 0);
    }
    66% {
      opacity: 1;
      transform: translate3d(-26.4px, 0, 0);
    }
    100% {
      opacity: 0;
      transform: translate3d(-40px, 0, 0);
    }
  }

  @keyframes copyWrap {
    0% {
      transform: translate3d(0, -100%, 0) translate3d(0, -10px, 0);
    }
    100% {
      transform: translate3d(0, 0, 0) translate3d(0, 0, 0);
    }
  }

  @keyframes copyImg {
    0% {
      transform: translate3d(0, 100%, 0);
    }
    100% {
      transform: translate3d(0, 0, 0);
    }
  }

  @keyframes scrollBar {
    0% {
      transform: scaleY(0);
      transform-origin: top;
    }
    49% {
      transform: scaleY(1);
      transform-origin: top;
    }
    50% {
      transform: scaleY(1);
      transform-origin: bottom;
    }
    100% {
      transform: scaleY(0);
      transform-origin: bottom;
    }
  }
  &__image {
    &-wrapper {
      opacity: 0;
      transform: translate3d(0, 0, 0);
      z-index: 0;
      &.is-animate {
        z-index: 5;
        animation-name: kv;
        animation-duration: 6s;
        animation-fill-mode: forwards;
        animation-timing-function: linear;
      }
    }
  }
  &__copy {
    &-item {
      @for $-i from 1 through 4 {
        &:nth-of-type(#{$-i}) & {
          &-wrap {
            animation-delay: (($-i - 1) * 0.1 + 5) * 1s;
            img {
              animation-delay: (($-i - 1) * 0.1 + 5) * 1s;
            }
          }
        }
      }
    }
    &.is-opening & {
      &-item {
        &-wrap {
          transform: translate3d(0, -100%, 0) translate3d(0, -10px, 0);
          animation-name: copyWrap;
          animation-duration: 1s;
          animation-timing-function: variables-easings.$easeOutCubic;
          animation-fill-mode: forwards;
          img {
            transform: translate3d(0, 100%, 0);
            animation-name: copyImg;
            animation-duration: 1s;
            animation-timing-function: variables-easings.$easeOutCubic;
            animation-fill-mode: forwards;
          }
        }
      }
    }
  }
  &__scroll {
    &-bar {
      overflow: hidden;
      &-in {
        transform: translate3d(0, -100%, 0);
        animation-name: scrollBar;
        animation-duration: 1s;
        animation-timing-function: variables-easings.$easeOutCubic;
        animation-iteration-count: infinite;
      }
    }
  }
  /* stylelint-enable no-duplicate-selectors, no-descending-specificity */
}
</style>
