<template lang="pug">
.zoom-in(
  v-scroll-item
  :is = 'tag'
  :style = "{ animationDelay: `${delay}s` }"
  )
  .zoom-in__wrap(
    :style = "{ animationDelay: `${delay}s` }"
    )
    slot
</template>

<script>
export default {
  name: 'ZoomInScrollItem',
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    delay: {
      type: String,
      default: '0',
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/assets/css/foundation/variables-easings';

@keyframes zoomIn {
  0% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
.zoom-in {
  overflow: hidden;
  opacity: 0;
  &__wrap {
    transform: scale(1.1);
    backface-visibility: hidden;
  }
  &.is-shown {
    animation-name: fadeIn;
    animation-duration: 0.5s;
    animation-timing-function: linear;
    animation-fill-mode: both;
  }
  &.is-shown & {
    &__wrap {
      animation-name: zoomIn;
      animation-duration: 0.7s;
      animation-timing-function: variables-easings.$easeOutCubic;
      animation-fill-mode: both;
    }
  }
}
</style>
