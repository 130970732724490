function initIntersectionObserver(el, option, callback) {
  const observer = new IntersectionObserver((intersectionObserverEntries) => {
    // 要素が少しでも指定範囲に入っていれば真
    if (intersectionObserverEntries[0].intersectionRatio > 0) {
      callback();
      observer.unobserve(intersectionObserverEntries[0].target);
    }
  }, option);

  observer.observe(el);
}
module.exports = initIntersectionObserver;
