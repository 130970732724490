<template lang="pug">
  .bar
    a.bar__btn.c-hover(
      :href = 'mixLink'
      @click = 'tagToApp'
      )
      |販売中の物件を見る
</template>

<script>
export default {
  name: 'Bar',
  props: {
    eventLabel: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      mixLink:
        'https://vcq8z.app.goo.gl/?link=https://cowcamo.jp/dispatch?path%3D/mixes/90&apn=com.tsukuruba.cowcamo.z&isi=1282237769&ibi=com.tsukuruba.cowcamo.z&ofl=https://cowcamo.jp/selec',
    };
  },
  methods: {
    tagToApp() {
      this.$gtag.event('click', {
        event_category: 'selec_list',
        event_label: this.eventLabel,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@use 'sass:math';
@use '@/assets/css/foundation/variables';
@use '@/assets/css/foundation/mixin';

.bar {
  &__btn {
    display: block;
    background-color: variables.$color-primary;
    background-image: url('~@/assets/images/common/btn_arrow.png');
    background-repeat: no-repeat;
    border-radius: 100px;
    color: #fff;
    font-weight: bold;
    letter-spacing: math.div(0.63, 15) * 1em;
    line-height: 1;
    text-decoration: none;
    text-align: center;

    @include mixin.l-more-than-mobile {
      padding: 24px 0;
      background-size: 24px;
      background-position: top 50% right math.div(24, 480) * 100%;

      @include mixin.fontSize(24);
    }

    @include mixin.l-mobile {
      padding: math.div(24, 335) * 100% 0;
      background-size: math.div(16, 335) * 100%;
      background-position: top 50% right math.div(24, 335) * 100%;

      @include mixin.fontSizeMobile(15);
    }
  }
}
</style>
